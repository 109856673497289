.cmn_global_footer {
	.pagetop {
		position: absolute;
		width: 100%;
		margin-top: (146px / 4) + 25;
		cursor: pointer;
		z-index: 1;

		a {
			position: fixed;
			bottom: 25px;
			right: 25px;
			display: block;
			width: (145px / 2);
			height: (145px / 2);
			border-radius: 50%;
			border: 1px solid $color_deepgreen;
			background: url(../images/common/pagetop.png) no-repeat center/contain;
			opacity: 0;
			transition: opacity 0.2s linear, border-width 0.1s ease;
			pointer-events: none;

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				box-sizing: border-box;
				width: 100%;
				height: 100%;
				border: 2px solid $color_deepgreen;
				border-radius: 50%;
				opacity: 0;
				@include basicTransition('opacity');
			}
			@include PC {
				&:hover {
					&:after {
						opacity: 1;
					}
				}
			}
			@include SP {
				width: (106px / 2);
				height: (106px / 2);
			}
		}
		&._stop {
			a {
				@include PC {
					position: absolute;
				}
			}
		}
		&._show {
			a {
				@include PC {
					pointer-events: auto;
					opacity: 1;
				}
			}
		}
		&._show._showshow  {
			a {
				@include SP {
					pointer-events: auto;
					opacity: 1;
				}
			}
		}
	}
	.navi {
		background-color: $color_palegray;
		$navi_width : 680px;
		$link_width : 325px;

		@include PC {
			padding-top: 70px;
			padding-bottom: 70px;
		}
		@include SP {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		.cmn_container {
			@include PC {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
			}
		}
		.menu {
			@include PC {
				display: flex;
				flex-wrap: wrap;
				width: ($navi_width / $pc_base_w) * 100%;
			}
			dl {
				@include PC {
					margin-top: 65px;

					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {
						margin-top: 0px;
					}
					&:nth-child(3n+1) {
						width: (220px / $navi_width) * 100%;
					}
					&:nth-child(3n+2) {
						width: (220px / $navi_width) * 100%;
					}
					&:nth-child(3n+3) {
						width: (($navi_width - 220px * 2) / $navi_width) * 100%;
					}
				}
				@include SP {
					padding-top: 20px;
					padding-bottom: 20px;
				}
				@include SP {
					padding-left: $sp_margin;
					padding-right: $sp_margin;
					border-bottom: 1px solid #e3e3e3;
				}
				a {
					color: inherit;
					letter-spacing: 0.04em;
				}
			}
			dt {
				font-weight: bold;

				@include PC {
					font-size: 17px;
				}
				@include SP {
					font-size: 15px;
				}
			}
			dd {
				display: flex;

				@include PC {
					margin-top: 10px;
				}
				ul {
					width: 100%;
				}
				li {
					@include PC {
						margin-top: 10px;
					}
					@include SP {
						margin-top: 15px;
					}
				}
			}
		}
		.link {
			@include PC {
				width: ($link_width / $pc_base_w) * 100%;
			}
			@include SP {
				padding: 50px $sp_margin 40px;
			}
			.btn {
				li:not(:first-child) {
					@include PC {
						margin-top: 15px;
					}
					@include SP {
						margin-top: 10px;
					}
				}
				a {
					@include SP {
						height: 70px;
					}
				}
			}
			.sns {
				@include PC {
					margin-top: 45px;
				}
				@include SP {
					margin-top: 50px;
				}
				li:not(:first-child) {
					margin-top: 15px;
				}
				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					box-sizing: border-box;
					overflow: hidden;
					height: 45px;
					padding: 5px 45px 5px 45px;
					background-color: #ffffff;
					border-radius: 3px;
					color: #3b5998;
					font-size: 15px;

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						content: "";
						width: 45px;
						height: 100%;
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
					}
					&.fb {
						&:before {
							background-image: url(../images/common/icon_fb.jpg);
						}
					}
				}
			}
		}
	}
	.main {
		background-color: $color_deepgreen;

		.cmn_container {
			@include PC {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 60px;
			}
		}
		.menu {
			display: flex;

			@include SP {
				margin: 0 auto;
				max-width: 400px;
				justify-content: space-between;
				padding: 25px $sp_margin;
			}
			@include SP(350px) {
				font-size: 11px;
			}
			li:not(:first-child) {
				@include PC {
					margin-left: 55px;
				}
			}
			a {
				color: #ffffff;
			}
		}
		.copy {
			display: block;
			color: #ffffff;

			@include PC {
				font-size: 12px;
			}
			@include SP {
				padding-top: 15px;
				padding-bottom: 15px;
				background: $color_basegreen;
				font-size: 11px;
				text-align: center;
				line-height: (40 / 22);
			}
		}
	}
}
