//******************************
.cmn_pc {
	@include SP { display: none!important; }
}
.cmn_sp {
	@include PC { display: none!important; }
}
//******************************
.cmn_alt {
	display: none!important;
}
//******************************
.cmn_link {
	color: $color_basicblue;
	text-decoration: underline;
}
//******************************
.cmn_hopacity {
	@include hopacity();
}
//******************************
.cmn_hogreen {
	@include hogreen();
}
//******************************
.cmn_container {
	@include PC {
		width: 100%;
		max-width: $pc_base_w;
		margin-left: auto;
		margin-right: auto;
	}
	@include SP {
		&:not(._break) {
			margin-left: $sp_margin;
			margin-right: $sp_margin;
		}
	}
}
//******************************
.cmn_contents {
	display: block;
	padding-bottom: 100px;

	@include SP {
		padding-bottom: 80px;
	}
	&._pita {
		padding-bottom: 0;
	}
}
//******************************
.cmn_contents_header {
	margin-bottom: 45px;

	&._pita {
		margin-bottom: 0;
	}
	@include SP {
		margin-bottom: 40px;
	}
	.breadcrumbs {
		padding-top: 25px;
		padding-bottom: 25px;
		font-size: 10px;

		@include SP {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		li {
			position: relative;
			display: inline-block;
			padding-right: 20px;

			a {
				color: $color_deepgreen;
				font-weight: bold;
				text-decoration: none;

				@include hopacity();
			}
			&:not(:last-child) {
				a {
					color: inherit;
				}
				&:before,
				&:after {
					content: "";
					position: absolute;
					right: 6px;
					display: block;
					width: 1px;
					height: 4px;
					background: #333333;
					transform-origin: center right;
				}
				&:before {
					bottom: 50%;
					transform: skewX(50deg)
				}
				&:after {
					top: 50%;
					transform: skewX(-50deg)
				}
			}
		}
	}
	.title {
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		margin-top: 20px;

		@include SP {
			margin-top: 25px;
		}
	}
}
//******************************
.cmn_contents_description {
	text-align: center;
	line-height: 1.7;

	@include SP {
		text-align: left;
	}
}
//******************************
.cmn_content {
}
//******************************
.cmn_content_header {

	&._type_A {
		margin-bottom: 15px;
		padding-bottom: 20px;
		border-bottom: 1px solid #eeeeee;

		.title {
			font-size: 20px;
			font-weight: bold;

			@include SP {
				font-size: 16px;
			}
		}
	}
	&._type_B,
	&._type_C {
		text-align: center;
		margin-bottom: 30px;

		@include SP {
			margin-bottom: 20px;
		}
		.title {
			font-size: 27px;
			font-weight: bold;

			@include SP {
				font-size: 20px;
			}
		}
	}
	&._type_B {
		.title {
			color: $color_deepgreen;
		}
	}
	&._type_C {
		.title {
			color: inherit;
		}
	}
}
//******************************
.cmn_content_description {
	line-height: 1.7;

	strong {
		font-weight: bold;
	}
	&._type_B {
		font-size: 14px;
		text-align: center;
		line-height: 2.0;

		strong {
			font-size: 16px;
		}
		@include SP {
			font-size: 13px;
			text-align: left;
			line-height: 1.7;

			strong {
				font-size: 13px;
			}
		}
	}
	.note {
		display: block;
		margin-top: 15px;
		font-size: 12px;

		li {
			display: inline-block;
		}
		@include SP {
			font-size: 11px;
		}
	}
}
//******************************
.cmn_box_content {
	padding-top: 80px;
	padding-bottom: 80px;

	@include SP {
		padding-top: 45px;
		padding-bottom: 45px;
	}
	&._gray {
		background-color: $color_lightgray;
	}
	&._green {
		background-color: $color_palegreen;
	}
}
//******************************
.cmn_box_content_header {
	display: table;
	width: 100%;

	.title {
		display: table-cell;
		box-sizing: border-box;
		height: 65px;
		padding-top: 7.5px;
		padding-bottom: 7.5px;
		text-align: center;
		vertical-align: middle;
		background-color: $color_deepgreen;
		color: #ffffff;
		font-size: 21px;
		font-weight: bold;

		@include SP {
			padding-top: 17px;
			padding-bottom: 17px;
			height: 60px;
			font-size: 16px;
		}
	}
	&._h {
		.title {
			height: 100px;
			font-size: 26px;

			@include SP {
				height: 60px;
				font-size: 19px;
			}
		}
	}
}
//******************************
.cmn_box_content_inner {
	padding: 15px 65px;
	background-color: #ffffff;

	@include SP {
		padding: 15px 25px 0px;
	}
}
//******************************
.cmn_section {
	&:not(:first-child) {
		margin-top: 75px;

		@include SP {
			margin-top: 40px;
		}
	}
}
//******************************
.cmn_section_header {
	padding-left: 15px;
	border-left: 5px solid $color_deepgreen;
	margin-bottom: 20px;

	@include SP {
		padding-left: 10px;
		margin-bottom: 15px;
	}
	.title {
		font-size: 20px;
		font-weight: bold;

		@include SP {
			font-size: 16px;
		}
	}
	&._redbar {
		border-color: $color_red;
	}
}
//******************************
.cmn_section_description {
	line-height: 1.7;
}
//******************************
.cmn_button {
	position: relative;
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 70px;
	padding: 0;
	border-style: solid;
	border-width: 1px;
	border-color: transparent;
	color: inherit;
	font-size: 15px;
	line-height: 1.35;
	text-decoration: none;
	cursor: pointer;
	overflow: hidden;
	appearance: none;
	transition-timing-function: ease;
	transition-duration: 0.2s;
	transition-property: border-color, background-color, color;
	outline: none;
	// @include hopacity();

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -0.5px;
		display: block;
		width: 15%;
		height: 1px;
		transition-timing-function: ease;
		transition-duration: 0.2s;
		transition-property: background-color;
	}
	@include SP {
		height: 60px;
		font-size: 14px;
	}
	span {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		padding-right: 15%;

		@include SP {
			padding-left: 20px;
			justify-content: flex-start;
		}
	}
	&._basic {
		border: 1px solid $color_deepgreen;
		background-color: #ffffff;

		&:after {
			background-color: $color_deepgreen;
		}
		@include PC {
			&:hover {
				background-color: $color_deepgreen;
				color: #ffffff;

				&:after {
					background-color: #ffffff;
				}
			}
		}
	}
	&._green {
		background-color: $color_deepgreen;
		color: #ffffff;

		&:after {
			background-color: #ffffff;
		}
		@include PC {
			&:hover {
				background-color: #ffffff;
				border-color: $color_deepgreen;
				color: $color_deepgreen;

				&:after {
					background-color: $color_deepgreen;
				}
			}
		}
	}
	&._white {
		background-color: #ffffff;
		font-weight: bold;

		&:after {
			background-color: #a9a9a9;
		}
		@include PC {
			&:hover {
				border-color: $color_deepgreen;

				&:after {
					background-color: $color_deepgreen;
				}
			}
		}
	}
	&._whitegreen {
		background-color: #ffffff;
		color: $color_deepgreen;

		&:after {
			background-color: $color_deepgreen;
		}
		@include PC {
			&:hover {
				background-color: $color_deepgreen;
				border-color: #ffffff;
				color: #ffffff;

				&:after {
					background-color: #ffffff;
				}
			}
		}
	}
	&._gray {
		border: 1px solid #aaaaaa;
		background-color: #f5f5f5;

		&:after {
			background-color: #aaaaaa;
		}
		@include PC {
			&:hover {
				border-color: $color_deepgreen;

				&:after {
					background-color: $color_deepgreen;
				}
			}
		}
	}
}
//******************************
.cmn_list {
	&._circ {
		@include list(circ);
	}
	&._disc {
		@include list(disc);
	}
	&._dot {
		@include list(dot);
	}
	&._kome {
		@include list(kome);
	}
	&._square {
		@include list(square);
	}
	&._custom {
		@include list(custom);
	}
}
//******************************
.cmn_elink {
	position: relative;
	display: inline-block;
	padding-right: 25px;

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -10px;
		display: block;
		width: 20px;
		height: 20px;
		background-size: contain;
		background-image: inline-svg(icon_externallink,$color:$color_basicblue);
	}
	&._s {
		padding-right: 22px;

		&:after {
			margin-top: -10px;
			width: 18px;
			height: 18px;
		}
	}
	&._black {
		&:after {
			background-image: inline-svg(icon_externallink,$color:#000000);
		}
	}
	&._white {
		&:after {
			background-image: inline-svg(icon_externallink,$color:#ffffff);
		}
	}
}
//******************************
.cmn_anchormenu {
	display: flex;
	justify-content: center;
	font-size: 18px;

	li {
		margin-left: 20px;
		margin-right: 20px;
		display: flex;
		align-items: stretch;
		text-align: center;

		@include PC {
			min-width: 220px;
		}
	}
	a {
		position: relative;
		display: block;
		width: 100%;
		padding-bottom: 10px;
		border-bottom: 3px solid #d9d9d9;
		color: inherit;
		text-decoration: none;
		cursor: pointer;

		@include PC {
			@include basicTransition('border-color,color');
		}
		&:after {
			@include sankaku('B', 14px, 10px, #d9d9d9, false, true) {
				bottom: -10px;
			}
			@include PC {
				@include basicTransition('border-color');
			}
		}
		&:hover {
			@include PC {
				color: $color_deepgreen;
				border-color: $color_deepgreen;

				&:after {
					border-top-color: $color_deepgreen;
				}
			}
		}
	}
	@include SP {
		&._type_A {
			margin-left: -$sp_margin;
			margin-right: -$sp_margin;
			border-top: 3px solid $color_deepgreen;

			li {
				margin-left: 0;
				margin-right: 0;

				&:not(:first-child) {
					margin-left: 1px;
				}
			}
			a {
				font-weight: bold;
				border-bottom: none;
				background-color: #f1f9f2;
				padding-top: 20px;
				padding-bottom: 38px;

				&:after {
					@include sankaku('B', 10px, 6px, $color_deepgreen, false, true) {
						bottom: 18px;
					}
				}
			}
		}
		&._type_B {
			display: block;
			margin-left: -$sp_margin;
			margin-right: -$sp_margin;
			border-top: none;

			li {
				margin-left: 0;
				margin-right: 0;

				&:not(:first-child) {
					margin-top: 1px;
				}
			}
			a {
				background-color: $color_lightgray;
				padding-top: 20px;
				padding-bottom: 20px;
				padding-left: 30px;
				padding-right: 40px;
				font-size: 15px;
				text-align: left;
				border-bottom: none;

				&:after {
					@include sankaku('B', 10px, 6px, $color_deepgreen, true, false) {
						left: auto;
						right: 30px;
					}
				}
			}
		}
	}
}
//******************************
.cmn_movie_play {
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -(86px / 4);
		margin-left: -(122px / 4);
		display: block;
		width: (122px / 2);
		height: (86px / 2);
		background-image: url(../images/common/icon_movie_play.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 2px solid transparent;
		transition: border-color 0.1s ease;
		box-sizing: border-box;
	}
	@include PC {
		&:hover {
			&:before {
				border-color: $color_basegreen;
			}
		}
	}
	@include SP {
		&:after {
			margin-top: -(86px / 4) * 0.8;
			margin-left: -(122px / 4) * 0.8;
			width: (122px / 2) * 0.8;
			height: (86px / 2) * 0.8;
		}
	}
}

//******************************
.cmn_point_icon {
	display: block;
	width: 50px;
	border-radius: 50%;
	overflow: hidden;

	span {
		display: block;
		height: 25px;
		box-sizing:border-box;
		line-height: 20px;
		text-align: center;
		white-space: nowrap;
		color: #ffffff;

		&:nth-child(1) {
			padding-top: 5px;
			background-color: $color_basegreen;
			font-size: 10px;
		}
		&:nth-child(2) {
			padding-bottom: 5px;
			background-color: $color_deepgreen;
			font-size: 12px;
		}
	}
	@include SP {
		&._type_A {
			width: 46px;

			span {
				height: 23px;
				line-height: 18px;

				&:nth-child(1) {
					font-size: 8px;
				}
				&:nth-child(2) {
					font-size: 10px;
				}
			}
		}
		&._type_B {
			display: flex;
			border-radius: 0;
			width: auto;

			span {
				width: auto;
				height: 25px;
				line-height: 25px;
				padding-left: 8px;
				padding-right: 8px;

				&:nth-child(1) {
					padding-top: 0;
					font-size: 12px;
				}
				&:nth-child(2) {
					padding-bottom: 0;
					font-size: 12px;
				}
			}
		}
	}
}

//******************************
.cmn_bottom_buttonarea {
	margin-top: 80px;

	@include SP {
		margin-top: 40px;

	}
	ul {
		background: $color_lightgray;
		padding: 60px 45px;
		display: flex;
		justify-content: space-between;

		@include SP {
			display: block;
			padding: 40px 25px;
		}
		li {
			width: 450px;

			@include SP {
				width: auto;

				&:not(:first-child) {
					margin-top: 20px;
				}
			}
		}
	}
}

.cmn_marker_yellow {
	background: -moz-linear-gradient(top, rgba(255,255,128,0) 50%, rgba(255,255,128,1) 51%, rgba(255,255,128,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(255,255,128,0) 50%,rgba(255,255,128,1) 51%,rgba(255,255,128,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(255,255,128,0) 50%,rgba(255,255,128,1) 51%,rgba(255,255,128,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}




.cmn_userform {
	margin-top: 40px;

	.formbox {
		background: $color_lightgray;
		padding: 50px 95px;

		@include SP {
			padding: 35px 20px 20px;
		}
		dl {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			@include SP {
				display: block;
			}
		}
		dt,
		dd {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			min-height: 50px;
			padding-top: 10px;
			padding-bottom: 10px;
			letter-spacing: 0.05em;

			@include SP {
				display: block;
				min-height: 100%;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		dt {
			width: 30%;
			font-size: 16px;
			font-weight: bold;

			@include SP {
				width: auto;
				font-size: 14px;
			}
			.required:after {
				content: "※";
				color: $color_red;
				margin-left: 0.5em;
			}
		}
		dd {
			width: 70%;

			@include SP {
				width: auto;
				margin-top: 5px;
				margin-bottom: 17px;
			}
			>span {
				display: block;
				width: 100%;
			}
		}
	}
	input,
	select,
	textarea,
	label {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0;
		box-sizing: border-box;
		font-size: 16px;

		&.full {
			width: 100%;
		}
		&.mini {
			width: 40%;
			@include SP {
				width: 100%;
			}
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	textarea,
	select {
		display: block;
		width: 100%;
		padding: 10px 20px;
		border: 1px solid #e0e0e0;
		background: #ffffff;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"] {
		height: 50px;
	}
	input::placeholder {
		color: #cccccc;
	}
	input.zipcode {
		padding-left: 40px;
		background-image: url(../images/common/icon_zipcode.png);
		background-repeat: no-repeat;
		background-position: center left 20px;
		background-size: 14px 14px;
	}
	select {
		height: 50px;
		padding-right: 30px;
		background-image: url(../images/common/icon_select.png);
		background-position: center right 10px;
		background-repeat: no-repeat;
		background-size: 13px 7.5px;
	}
	select::-ms-expand {
		display: none;
	}
	textarea {
		height: 200px;
	}
	.radio,
	.checkbox {
		> span {
			margin-left: 0;

			label {
				display: inline-block;
				padding-top: 10px;
				padding-bottom: 10px;
			}
			input {
				display: none;
			}
			span {
				position: relative;
				padding-left: 35px;
				margin-right: 15px;
				display: inline-block;
				font-size: 14px;
				cursor: pointer;

				@include SP {
					font-size: 13px;
				}
				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0.75em;
					left: 0;
					display: block;
					box-sizing: border-box;
				}
			}
			input:checked+span {
				&:after {
					display: block;
				}
			}
		}
	}
	.checkbox {
		>span {
			display: block;

			label {
				display: block;
			}
			span {
				&:before {
					margin-top: -13px;
					width: 24px;
					height: 24px;
					border: 1px solid #e0e0e0;
					background: #ffffff;
				}
				&:after {
					margin-top: -11px;
					margin-left: 5px;
					width: 14px;
					height: 12px;
					border-left: 3px solid $color_deepgreen;
					border-bottom: 3px solid $color_deepgreen;
					transform: rotate(-45deg);
					display: none;
				}
			}
		}
	}
	.radio {
		>span {
			display: inline-block;

			label {
				display: inline-block;
			}
			span {
				&:before {
					margin-top: -13px;
					width: 24px;
					height: 24px;
					border: 1px solid #e0e0e0;
					background: #ffffff;
					border-radius: 50%;
				}
				&:after {
					margin-top: -7px;
					margin-left: 6px;
					width: 12px;
					height: 12px;
					background: $color_deepgreen;
					border-radius: 50%;
					display: none;
				}
			}
		}
	}
	.text {
		padding-top: 10px;
		font-size: 14px;

		@include SP {
			padding-top: 5px;
			font-size: 13px;
		}
	}
	.policy {
		margin-top: 40px;
		font-size: 14px;
		text-align: center;

		@include SP {
			margin-top: 25px;
			margin-bottom: 50px;
			text-align: left;
			font-size: 13px;
		}
		.policy_checkbox {
			margin-top: 25px;

			label {
				font-size: 16px;
				font-weight: bold;
				display: block;
				margin: 0 auto;
			}
			@include SP {
				margin-top: 5px;
			}
		}
	}
	.wpcf7-not-valid-tip {
		margin-top: 10px;
		display: block;
		width: 100%;
		font-size: 13px;
		color: $color_red;

		&:before {
			content: "※";
		}
		@include SP {
			font-size: 12px;
			margin-top: 10px;
		}
	}
	.checkbox,
	.radio {
		+ .wpcf7-not-valid-tip {
			margin-top: 0;
		}
	}
	.button {
		position: relative;
		max-width: 450px;
		margin: 45px auto 0;

		.cmn_button {
			width: 100%;
			margin: 0 auto;
		}
		.ajax-loader {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.7);
			z-index: 1000;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.2s linear;

			&:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 50px;
				height: 50px;
				margin-top: -25px;
				margin-left: -25px;
				border-radius: 50%;
				border-top: 5px solid rgba(255, 255, 255, 0.2);
				border-right: 5px solid rgba(255, 255, 255, 0.2);
				border-bottom: 5px solid rgba(255, 255, 255, 0.2);
				border-left: 5px solid #ffffff;
				transform: translateZ(0);
				animation: load8 1.1s infinite linear;
				transition: opacity 0.5s ease;
				box-sizing: border-box;
			}
			&.is-active {
				opacity: 1;
			}
		}
	}
}
