body {
	color: #333333;
	font-size: 13px;
	font-family: "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
	line-height: 1.5;
	letter-spacing: 0.08em;
	min-width: 320px;

	@include PC {
		min-width: $pc_base_w + $pc_margin * 2;
		padding-top: $pc_header_h;
	}
	@include SP {
		padding-top: $sp_header_h;
	}
}
a {
	color: $color_deepgreen;
	text-decoration: none;
}
img {
	max-width: 100%;
}

sup {
	font-size: 60%;
	vertical-align: top;
}
sub {
	font-size: 60%;
	vertical-align: bottom;
}


.fancybox-slide {
	@include SP {
		padding: 15px!important;
	}
}
.fancybox-button--close {
	margin: 10px!important;
	border-radius: 50%!important;
	background: rgba(0,0,0,0.5)!important;
}
