@mixin PC($minwidth:$breakpoint + 1) {
	@media screen and (min-width:$minwidth) {
		@content;
	}
	@media print {
		@content;
	}
}

@mixin SP($maxwidth:$breakpoint) {
	@media screen and (max-width:$maxwidth) {
		@content;
	}
}

@mixin hopacity() {
	@include PC {
		opacity: 1;
		transition: opacity 0.1s linear;

		&:hover {
			opacity: 0.75;
		}
	}
}

@mixin hogreen() {
	@include PC {
		transition: color 0.1s linear;

		&:hover {
			color: $color_deepgreen!important;
		}
	}
}

@mixin basicTransition($property) {
	transition-duration: 0.1s;
	transition-timing-function: linear;
	transition-property: $property;
	@content;
}

@mixin hamburger($color, $thickness) {
	@content;

	.icon {
		hr {
			position: absolute;
			margin: -($thickness / 2) 0 0 0;
			display: block;
			width: 100%;
			height: $thickness;
			background: $color;
			border: none;
			transition: top 0.2s 0.3s linear, opacity 0.0s 0.3s linear, transform 0.3s 0.0s linear;
		}
		hr:nth-child(1) {
			top: 0;
		}
		hr:nth-child(2) {
			top: 100%;
		}
		hr:nth-child(3) {
			top: 50%;
		}
	}
	&.open .icon {
		hr {
			transition: top 0.2s 0.0s linear, opacity 0.0s 0.2s linear, transform 0.3s 0.2s linear;
		}
		hr:nth-child(1) {
			top: 50%;
			transform: rotate(225deg);
		}
		hr:nth-child(2) {
			top: 50%;
			transform: rotate(135deg);
		}
		hr:nth-child(3) {
			opacity: 0;
		}
	}
}

@mixin triangle($direction, $w, $h, $color, $vcenter:true, $hcenter:true) {
	&:after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;

		@if $direction == 'T' {
			border-width: 0 ($w / 2) $h ($w / 2);
			border-color: transparent transparent $color transparent;
		}
		@if $direction == 'B' {
			border-width: $h ($w / 2) 0 ($w / 2);
			border-color: $color transparent transparent transparent;
		}
		@if $direction == 'R' {
			border-width: ($h / 2) $w ($h / 2) 0;
			border-color: transparent $color transparent transparent;
		}
		@if $direction == 'L' {
			border-width: ($h / 2) 0 ($h / 2) $w;
			border-color: transparent transparent transparent $color;
		}
		@if $vcenter == true {
			position: absolute;
			top: 50%;
			margin-top: -($h / 2);
		}
		@if $hcenter == true {
			position: absolute;
			left: 50%;
			margin-left: -($w / 2);
		}
		@content;
	}
}
@mixin sankaku($direction, $w, $h, $color, $vcenter:true, $hcenter:true) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0;
	border-color: transparent;

	@if $direction == 'T' {
		border-left-width: ($w / 2);
		border-right-width: ($w / 2);
		border-bottom-width: $h;
		border-bottom-color: $color;
	}
	@if $direction == 'B' {
		border-left-width: ($w / 2);
		border-right-width: ($w / 2);
		border-top-width: $h;
		border-top-color: $color;
	}
	@if $direction == 'L' {
		border-top-width: ($h / 2);
		border-bottom-width: ($h / 2);
		border-right-width: $w;
		border-right-color: $color;
	}
	@if $direction == 'R' {
		border-top-width: ($h / 2);
		border-bottom-width: ($h / 2);
		border-left-width: $w;
		border-left-color: $color;
	}
	@if $vcenter == true {
		position: absolute;
		top: 50%;
		margin-top: -($h / 2);
	}
	@if $hcenter == true {
		position: absolute;
		left: 50%;
		margin-left: -($w / 2);
	}
	@content;
}


@mixin cross($color, $lineSize, $lineThicknesss, $lineAngle: 0deg) {

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -($lineThicknesss / 2);
		margin-left: -($lineSize / 2);
		width: $lineSize;
		height: $lineThicknesss;
		background: $color;
		transition: transform 0.25s ease;
		@content;
	}
	&:before {
		transform: rotate($lineAngle);
	}
	&:after {
		transform: rotate($lineAngle + 90);
	}
}

@function imginfo($image, $half:true, $sp:false) {
	$ratio : 1;

	@if $half == true {
		$ratio : 2
	}
	$info : (
		URL : url(#{$image}),
		W : width($image, $ratio),
		H : height($image, $ratio),
		w : width($image, $ratio * 2),
		h : height($image, $ratio * 2)
	);
	@if $sp == true {
		$image_sp: $image;
		$image_sp: replace($image_sp, '.jpg', '_sp.jpg');
		$image_sp: replace($image_sp, '.png', '_sp.png');
		$image_sp: replace($image_sp, '.gif', '_sp.gif');

		$info: map-merge($info, (
			SP_URL : url(#{$image_sp}),
			SP_W : width($image_sp, $ratio),
			SP_H : height($image_sp, $ratio),
			SP_w : width($image_sp, $ratio * 2),
			SP_h : height($image_sp, $ratio * 2)
		));
	}
	@return $info;
}

@function replace($string, $substr, $newsubstr, $all: 0) {
	$position-found: str-index($string, $substr);
	$processed: ();

	@if $position-found {
		@while ($position-found > 0) {
			$length-substr: str-length($substr);
			$processed: append($processed, str-slice($string, 0, $position-found - 1));
			$processed: append($processed, $newsubstr);
			$string: str-slice($string, $position-found + $length-substr);
			$position-found: 0;

			@if ($all > 0) {
				$position-found: str-index($string, $substr);
			}
		}
		$processed: append($processed, $string);
		$string: "";

		@each $s in $processed {
			$string: #{$string}#{$s};
		}
	}
	@return $string;
}

@function parseInt($n) {
    @return $n / ($n * 0 + 1);
}



@mixin list($type) {
	> li {
		position: relative;
		padding-left: 20px;
		line-height: 1.5;
		list-style-type: none!important;
		background-color: transparent;
	}
	> li:before {
		content: "";
		position: absolute;
		top: 0.75em;
		left: 0;
		display: block;
		box-sizing: border-box;
	}
	@if $type == 'circ' {
		> li:before {
			left: 2px;
			margin-top: -3px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			border: 1px solid #333333;
		}
	}
	@if $type == 'disc' {
		> li {
			padding-left: 1.5em;
		}
		> li:before {
			left: 2px;
			margin-top: -3px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #333333;
		}
	}
	@if $type == 'dot' {
		> li:before {
			left: 4px;
			margin-top: -2px;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #333333;
		}
	}
	@if $type == 'kome' {
		> li:before {
			content: "※";
			left: 0;
			margin-top: -0.75em;
		}
	}
	@if $type == 'square' {
		> li:before {
			content: "";
			position: absolute;
			top: 0.75em;
			left: 0;
			margin-top: -5px;
			width: 10px;
			height: 10px;
			background: $color_deepgreen;
		}
		&._l {
			> li {
				padding-left: 25px;
			}
			> li:before {
				margin-top: -7.5px;
				display: block;
				width: 15px;
				height: 15px;
			}
		}
	}
	@if $type == 'custom' {
		> li {
			padding-left: 2.5em;

			> span:first-child {
				display: inline-block;
				width: 2.5em;
				margin-left: -2.5em;
			}
		}
		> li:before {
			display: none;
		}
	}
}
