.cmn_global_header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;

	a {
		text-decoration: none;
	}
	@include PC {
		min-width: $pc_base_w + $pc_margin * 2;
	}
	.main {
		position: relative;
		background: $color_deepgreen;

		.cmn_container {
			position: relative;

			@include PC {
				height: $pc_header_main_h;
			}
			@include SP {
				height: $sp_header_main_h;
			}
		}
		.logo {
			position: absolute;

			@include PC {
				top: 16px;
				left: 0px;
				width: (276px / 2);
				height: (48px / 2);
			}
			@include SP {
				top: 12px;
				left: $sp_margin;
				width: (180px / 2);
				height: (48px / 2) * (180 / 276);
			}
			a {
				display: block;
				width: 100%;
				height: 100%;
				background: url(../images/common/logo.png) no-repeat center/contain;
			}
		}
		.burger {
			@include PC {
				display: none;
			}
			@include SP {
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 25px + $sp_margin * 2;

				.icon {
					position: absolute;
					top: 50%;
					left: 50%;
					margin-top: -(16px / 2) - 1;
					margin-left: -(25px / 2);
					width: 25px;
					height: 16px;
				}
				@include hamburger(#ffffff, 2px);
			}
		}
	}
	.navi {
		@include PC {
			position: relative;
			background-color: #ffffff;
			border-bottom: 1px solid $color_basegray;

			.menu {
				display: flex;
				justify-content: center;
				width: 100%;

				> li {
					width: 20%;
					border-left: 1px solid $color_basegray;
					box-sizing: border-box;

					&:last-child {
						border-right: 1px solid $color_basegray;
					}
					> a {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: $pc_header_navi_h;
						background: #ffffff;
						color: inherit;
						font-size: 14px;
						cursor: pointer;
						@include basicTransition('color, background-color');

						&.subnavi-button {
							position: relative;

							&:after {
								@include sankaku(B, 10px, 5px, $color_deepgreen, true, false) {
									right: 15px;
									@include basicTransition('border-top-color');
								}
							}
						}
					}
					&:hover {
						> a {
							background: $color_basegreen;
							color: #ffffff;

							&.subnavi-button {
								&:after {
									border-top-color: #ffffff;
								}
							}
						}
					}
				}
			}
			.link {
				position: absolute;
				bottom: 100%;
				right: 50%;
				margin-right: -($pc_base_w / 2);
				display: flex;
				height: $pc_header_main_h;
				align-items: center;

				li {
					&:not(:first-child) {
						margin-left: 45px;
					}
				}
				a {
					color: #ffffff;
				}
			}
			.subnavi {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: 0;
				overflow: hidden;
				background: $color_basegreen;

				.submenu {
					display: flex;
					width: 100%;
					height: auto!important;
					justify-content: center;
					z-index: 1;
					padding-top: 10px;
					padding-bottom: 10px;

					> li {
						min-width: 20%;
						box-sizing: border-box;
						padding-left: 20px;
						padding-right: 20px;

						> a {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 100%;
							height: 60px;
							color: #ffffff;
						}
					}
				}
			}
		}
		@include SP {
			position:fixed;
			top: $sp_header_main_h;
			left: 0;
			width: 100%;
			bottom: 100%;
			overflow: hidden;
			background-color: rgba(0,0,0,0.5);
			transition: bottom 0.5s ease;

			&.navi-open {
				bottom: 0;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
				overflow-scrolling: touch;
			}
			li {
				a {
					display: flex;
					align-items: center;
					width: 100%;
					min-height: 55px;
					padding-top: 5px;
					padding-bottom: 5px;
					padding-left: $sp_margin;
					padding-right: $sp_margin;
					box-sizing: border-box;
					font-size: 15px;
					font-weight: bold;
				}
				&:not(:first-child) a {
					border-style: solid;
					border-width: 1px 0 0 0;
				}
			}
			.menu {
				> li {
					> a {
						background-color: #ffffff;
						border-color: $color_basegray;
						color: inherit;
					}
				}
			}
			.submenu {
				height: 0;
				overflow: hidden;

				> li {
					> a {
						padding-left: $sp_margin + 15px;
						background-color: $color_basegreen;
						border-color: #bfe4a8;
						color: #ffffff;
						font-size: 14px;
					}
				}
			}
			.link {
				> li {
					> a {
						background-color: $color_deepgreen;
						border-color: #82d188;
						color: #ffffff;
					}
				}
			}
			.subnavi {
				height: auto!important;
			}
			.subnavi-button {
				position: relative;

				&:after {
					@include sankaku(B, 12px, 7px, $color_deepgreen, true, false) {
						right: 30px;
						transform: rotateX(0deg);
						transition: transform 0.2s ease;
					}
				}
				&.accordion_open {
					&:after {
						transform: rotateX(180deg);
					}
				}
			}
		}
	}
}
